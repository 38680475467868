@import 'modules/nav';

* {
	box-sizing: border-box;
}

.et-animated-content {
	position: relative;
	z-index: 1;
}

#et-main-area {
	padding-top: 40px;
}

.et_pb_promo_button {
	&:hover {
		color: #2ea3f2;
	}
}

.et_mobile_menu {
	opacity: 0;
	margin-top: -30px;
	transition: all ease-in-out 0.3s;
}

#footer-bottom {
	background: #030405 !important;
}

#footer-info,
#footer-info a {
	color: #898989;
	font-size: 14px;
	font-weight: 500;
}

#footer-info a,
#footer-widgets a {
	&:hover {
		color: #2ea3f2 !important;
	}
}

.mgnlPlaceholder {
	z-index: 1 !important;
}