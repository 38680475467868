@import '../variables';

#top-menu-nav a:hover {
  color: $color-jabil-light-blue;
}

#top-menu,
#top-menu-nav {
  line-height: 0;
  display: flex;
  align-items: baseline;
  list-style: none;
}

.magnolia-editmode {
  .nav li {
    vertical-align: top;
  }
 
  .nav li ul{
    position: relative;
    visibility: visible;
    opacity: 1;
  }

  #main-header,
  #top-header {
    position: relative !important;
  }
    
}

.menu-item-has-children  > a:first-child:after {
  content: "" !important;
  width: 5px;
  height: 5px;
  border-bottom: 1.5px solid currentColor;
  border-right: 1.5px solid currentColor;
  margin-top: 3px;
  transform: rotate(45deg);
}

.nav__btnRequest {
	margin: -12px 0;
}

.nav__btnRequestText {
  display: inline-block;
  text-align: center;
  padding: 12px 10px !important;

  @media screen and (max-width: $width-tablet-max) {
    font-size: 15px !important;
  }
}
